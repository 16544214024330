// ----------------------------------------------------------------------

export function checkbox(theme) {
	return {
		MuiCheckbox: {
			styleOverrides: {
				root: {
					padding: theme.spacing(1),
				},
			},
		},
	};
}
