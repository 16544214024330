"use client";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// utils
import { localStorageGetItem } from "src/utils/storage-available";
//
import { defaultLang } from "./config-lang";
//
import translationEn from "./langs/en.json";
import translationCn from "./langs/cn.json";

// ----------------------------------------------------------------------

const lng = localStorageGetItem("i18nextLng", defaultLang.value);

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			cn: { translations: translationCn },
			en: { translations: translationEn },
		},
		lng,
		fallbackLng: lng,
		debug: false,
		ns: ["translations"],
		defaultNS: "translations",
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
