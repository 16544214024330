"use client";

import PropTypes from "prop-types";
import { useEffect, useReducer, useCallback, useMemo, useRef } from "react";
// utils
import axios, { endpoints, fetcher } from "src/utils/axios";
//
import { AuthContext } from "./auth-context";
import { isValidToken, setSession } from "./utils";
import { useBoolean } from "src/hooks/use-boolean";
import { useLocalStorage } from "src/hooks/use-local-storage";
import useSWR, { mutate } from "swr";
import { enqueueSnackbar } from "notistack";
import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
	user: null,
	loading: true,
};

const reducer = (state, action) => {
	if (action.type === "INITIAL") {
		return {
			loading: false,
			user: action.payload.user,
		};
	}
	if (action.type === "LOGIN") {
		return {
			...state,
			user: action.payload.user,
		};
	}
	if (action.type === "REGISTER") {
		return {
			...state,
			user: action.payload.user,
		};
	}
	if (action.type === "LOGOUT") {
		return {
			...state,
			user: null,
		};
	}
	if (action.type === "RESET") {
		return {
			...state,
			user: action.payload.user,
		};
	}
	if (action.type === "SEND_SMS") {
		return {
			...state,
			data: action.payload.data,
		};
	}
	return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = "accessToken";

export function AuthProvider({ children }) {
	const [state, dispatch] = useReducer(reducer, initialState);
	const initialized = useRef(false);
	const { data: userData } = useSWR(initialized.current ? endpoints.user.me : null, fetcher);
	const router = useRouter();
	const initialize = useCallback(async () => {
		try {
			const accessToken = localStorage.getItem(STORAGE_KEY);
			if (accessToken && isValidToken(accessToken)) {
				setSession(accessToken);
				const response = await axios.get(endpoints.user.me);

				const user = response.data.data;
				initialized.current = true;
				dispatch({
					type: "INITIAL",
					payload: {
						user,
					},
				});
			} else {
				dispatch({
					type: "INITIAL",
					payload: {
						user: null,
					},
				});
			}
		} catch (error) {
			console.error(error);
			dispatch({
				type: "INITIAL",
				payload: {
					user: null,
				},
			});
		}
	}, []);

	useEffect(() => {
		initialize();
	}, [initialize]);

	// LOGIN
	const pwdLogin = useCallback(async (email, password) => {
		const data = {
			email,
			password,
		};

		const response = await axios.post(endpoints.auth.password_login, data);

		const { accessToken, user } = response.data.data;

		setSession(accessToken);
		initialized.current = true;
		dispatch({
			type: "LOGIN",
			payload: {
				user,
			},
		});
	}, []);

	// REGISTER
	const recover = useCallback(async (email, code, password, RequestId, BizId) => {
		const data = {
			email,
			code,
			password,
			RequestId,
			BizId,
		};

		const response = await axios.patch(endpoints.auth.forget, data);

		const { accessToken, user } = response.data.data;

		setSession(accessToken);
		initialized.current = true;

		dispatch({
			type: "REGISTER",
			payload: {
				user,
			},
		});
	}, []);

	// REGISTER
	const register = useCallback(async (email, code, password, RequestId, BizId,name) => {
		const data = {
			email,
			code,
			password,
			RequestId,
			BizId,
			name
		};

		const response = await axios.post(endpoints.auth.register, data);

		const { accessToken, user } = response.data.data;

		setSession(accessToken);
		initialized.current = true;

		dispatch({
			type: "REGISTER",
			payload: {
				user,
			},
		});
	}, []);

	const smsLogin = useCallback(
		async (phone, code, RequestId, BizId, countryCode, countryCallingCode) => {
			const data = {
				phone,
				code,
				RequestId,
				BizId,
				countryCode,
				countryCallingCode,
			};

			const response = await axios.post(endpoints.auth.phone_login, data);

			const { accessToken, user } = response.data.data;

			setSession(accessToken);
			initialized.current = true;
			dispatch({
				type: "LOGIN",
				payload: {
					user,
				},
			});
		},
		[],
	);

	const passportLogin = useCallback(async (app, state, token) => {
		const data = {
			app,
			state,
			token,
		};

		const response = await axios.post(endpoints.auth.passport_login, data);

		const { accessToken, user } = response.data.data;

		setSession(accessToken);
		initialized.current = true;
		dispatch({
			type: "LOGIN",
			payload: {
				user,
			},
		});
	}, []);

	// SEND_SMS
	const send_sms = useCallback(async phone => {
		const formdata = {
			phone,
		};

		const response = await axios.post(endpoints.auth.send_sms, formdata);

		const { data } = response.data;

		dispatch({
			type: "SEND_SMS",
			payload: {
				data,
			},
		});
		return data;
	}, []);

	// LOGOUT
	const logout = useCallback(async () => {
		const response = await axios.post(endpoints.auth.logout);

		if (response.data.code === 200) {
			setSession(null);
			router.replace("/");
			// initialized.current = false;

			// await mutate(
			// 	() => true,
			// 	undefined,
			// 	{ revalidate: false }
			// );
		}

		// dispatch({
		// 	type: "LOGOUT",
		// });
	}, []);

	// ----------------------------------------------------------------------

	const checkAuthenticated = state.user ? "authenticated" : "unauthenticated";

	const status = state.loading ? "loading" : checkAuthenticated;
	const onSwitching = useBoolean(false);
	const switchOwner = useCallback(async organizationId => {
		onSwitching.onTrue();
		try {
			await axios.post(endpoints.owner.root, {
				type: organizationId ? 1 : 0,
				organization_id: organizationId,
			});
			await mutate(() => true);
		} catch (error) {
			console.error(error);
			enqueueSnackbar(typeof error?.data === "string" ? error.data : error.message.toString(), {
				variant: "error",
				persist: true,
			});
		}
		onSwitching.onFalse();
	},[onSwitching]);
	const { data: owner, isLoading: ownerIsLoading } = useSWR(
		initialized.current ? endpoints.owner.root : null,
		fetcher,
	);
	const { data: organization, isLoading: orgIsLoading } = useSWR(
		owner && owner.type === 1 && owner.id ? endpoints.organization.in_organization(owner.id) : null,
		fetcher,
	);

	const AuthDialogToggle = useBoolean(false);
	const memoizedValue = useMemo(
		() => ({
			user: userData ? userData : state.user,
			method: "jwt",
			loading: status === "loading",
			authenticated: status === "authenticated",
			unauthenticated: status === "unauthenticated",
			AuthDialogToggle,

			//
			pwdLogin,
			recover,
			logout,
			send_sms,
			register,
			smsLogin,
			passportLogin,
			organization,
			switchOwner,
			switchingOwner: onSwitching.value || ownerIsLoading || orgIsLoading,
			owner,
		}),
		[
			userData,
			state.user,
			status,
			AuthDialogToggle,
			pwdLogin,
			recover,
			logout,
			send_sms,
			register,
			smsLogin,
			passportLogin,
			organization,
			switchOwner,
			onSwitching.value,
			ownerIsLoading,
			orgIsLoading,
			owner,
		],
	);

	return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
	children: PropTypes.node,
};
