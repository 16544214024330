'use client';

import PropTypes from 'prop-types';
import { useRef, forwardRef } from 'react';
import { SnackbarProvider as NotistackProvider, closeSnackbar } from 'notistack';
// @mui
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
//
import Iconify from '../iconify';
import { useSettingsContext } from '../settings';
//
import { StyledIcon, StyledNotistack } from './styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import truncateFileName from 'src/utils/truncate-file-name';

// ----------------------------------------------------------------------

export default function SnackbarProvider({ children }) {
  const settings = useSettingsContext();

  const isRTL = settings.themeDirection === 'rtl';

  const notistackRef = useRef(null);

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={15}
      preventDuplicate
      autoHideDuration={3000}
      TransitionComponent={isRTL ? Collapse : undefined}
      variant="success" // Set default variant
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      iconVariant={{
        info: (
          <StyledIcon color="info">
            <Iconify icon="eva:info-fill" width={24} />
          </StyledIcon>
        ),
        success: (
          <StyledIcon color="success">
            <Iconify icon="eva:checkmark-circle-2-fill" width={24} />
          </StyledIcon>
        ),
        warning: (
          <StyledIcon color="warning">
            <Iconify icon="eva:alert-triangle-fill" width={24} />
          </StyledIcon>
        ),
        error: (
          <StyledIcon color="error">
            <Iconify icon="solar:danger-bold" width={24} />
          </StyledIcon>
        ),
        uploading: (
          <StyledIcon color="success">
            <Iconify icon="mdi:upload-circle" width={24} />
          </StyledIcon>
        ),
      }}
      Components={{
        default: StyledNotistack,
        info: StyledNotistack,
        success: StyledNotistack,
        warning: StyledNotistack,
        error: StyledNotistack,
        uploading: forwardRef((props, ref) => {
          return (
            <Stack ref={ref}>
              <StyledNotistack
                {...props}
                message={
                <Stack direction={'column'} spacing={1}>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography color={'text.disabled'} variant={'subtitle2'}>
                      Uploading -
                    </Typography>
                  <Typography variant={'subtitle2'}>
                    {truncateFileName(props.message, 80)}
                  </Typography>
                  </Stack>
                  <LinearProgress color="success" />
                </Stack>
                }
                action={null}
              />
            </Stack>
          );
        }),
      }}
      // with close as default
      action={snackbarId => (
        <IconButton size="small" onClick={() => closeSnackbar(snackbarId)} sx={{ p: 0.5 }}>
          <Iconify width={16} icon="mingcute:close-line" />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  );
}

SnackbarProvider.propTypes = {
  children: PropTypes.node,
};
