export default function truncateFileName(fileName, maxLength) {
  if (fileName.length <= maxLength) {
    return fileName;
  }

  const extension = fileName.split('.').pop();
  const name = fileName.slice(0, -(extension.length + 1));
  const charsToShow = Math.floor((maxLength - 3 - extension.length) / 2);

  return `${name.slice(0, charsToShow)}...${name.slice(-charsToShow)}.${extension}`;
}