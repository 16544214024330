"use client";

import merge from "lodash/merge";
import {
	enUS as enUSAdapter,
	zhCN as zhCNAdapter,
} from "date-fns/locale";
// core
import {
	enUS as enUSCore,
	zhCN as zhCNCore,
} from "@mui/material/locale";
// date-pickers
import {
	enUS as enUSDate,
	zhCN as zhCNDate,
} from "@mui/x-date-pickers/locales";
// data-grid
import {
	enUS as enUSDataGrid,
	zhCN as zhCNDataGrid,
} from "@mui/x-data-grid";

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
	{
		label: "中文",
		value: "cn",
		systemValue: merge(zhCNDate, zhCNDataGrid, zhCNCore),
		adapterLocale: zhCNAdapter,
		icon: "flagpack:cn",
	},
	{
		label: "English",
		value: "en",
		systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
		adapterLocale: enUSAdapter,
		icon: "flagpack:gb-nir",
	},
];

export const defaultLang = allLangs[1]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
