import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/simplebar-react/dist/simplebar.min.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-lazy-load-image-component/src/effects/blur.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/styles/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/auth/context/jwt/auth-consumer.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/auth/context/jwt/auth-context.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/auth/context/jwt/auth-provider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/animate/motion-lazy.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/progress-bar/progress-bar.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/progress-bar/styles.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/settings/context/settings-context.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/settings/context/settings-provider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/settings/drawer/settings-drawer.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/snackbar/snackbar-provider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/locales/config-lang.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/locales/i18n.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/locales/localization-provider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/locales/use-locales.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/theme/index.js")